<template>
	<main>
		<section class="layout-full_width" >
			<h1 class="page-title">
				<nice-icon2
					icon="man"
					class="page-title-icon"
				/>
				{{ $t('page_caption_advertiser-list') }}
			</h1>


			<TableTopPanel
				v-model="filterOptions"
				:add-title="isDSP ? $t('advertiser.add_advertiser') : '' | capitalize"
				:add-route="{
					name: 'advertiser-add',
				}"
				:statusList="statusFilter"
			/>


			<table class="nice-table">
				<thead>
					<tr>
						<th>{{ $t('advertiser.advertiser_column_label') }}&nbsp;&mdash;</th>
						<th>{{ $t('advertiser.status_column_label') }}&nbsp;&mdash;</th>
						<th>{{ $t('advertiser.phone_number_column_label') }}&nbsp;&mdash;</th>
						<th>{{ $t('advertiser.contact_column_label') }}&nbsp;&mdash;</th>
						<th>{{ $t('advertiser.email_column_label') }}&nbsp;&mdash;</th>
						<th>{{ $t('advertiser.company_website_label') }}&nbsp;&mdash;</th>
						<th>{{ $t('advertiser.created_column_label') }}&nbsp;&mdash;</th>

						<th>&nbsp;</th>
					</tr>
				</thead>

				<tbody>
					<template v-if="totalCount">
						<tr
							v-for="advertiser in advertisers"
							:key="advertiser.id"
							:class="`advertiser_${advertiser.id}_row`"
							class="advertiser-row"

							@click="editAdvertiser(advertiser.id)"
						>
							<td>{{ advertiser.name }}</td>

							<td
								v-if="isDSP"
								class="status-cell"
							>
								<div
									v-for="ssp_agency in advertiser.ssp_agencies_instances"
									:key="ssp_agency.id"
									class="ssp-agency-status-wrapper"
								>
									<span
										class="ssp-agency-name"
									>{{ ssp_agency.name }}</span>

									<span
										class="ssp-agency-status"
									>
										<NiceIcon2
											:icon="actions[ssp_agency.status].icon"
											class="status-icon"
										/>
										{{ $t('advertiser.status_change_' + ssp_agency.status) | capitalize }}
									</span>
								</div>
							</td>
							<td
								v-else
								class="status-cell"
							>
								<div
									class="ssp-agency-status-wrapper"
								>
									<span
										class="ssp-agency-name"
									>{{ advertiser.dsp_agency_instance.name }}</span>

									<span
										class="ssp-agency-status"
									>
										<NiceIcon2
											:icon="actions[advertiser.dsp_agency_instance.status].icon"
											class="status-icon"
										/>
										{{ $t('advertiser.status_change_' + advertiser.dsp_agency_instance.status) | capitalize }}
									</span>
								</div>
							</td>

							<td>{{ advertiser.phone }}</td>
							<td>{{ advertiser.contact_details }}</td>
							<td>{{ advertiser.email }}</td>
							<td>{{ advertiser.site }}</td>
							<td>{{ formatDateTime(advertiser.ctime) }}</td>

							<td></td>
						</tr>
					</template>
				</tbody>
			</table>


			<TablePaginator
				v-model="pagination"
				:total="totalCount"
				:paginator-props="{ queryKey: 'p' }"
			/>

		</section>

		<!-- Add/update/delete advertiser modals are here -->
		<router-view
			@close-add-advertiser="closeAddAdvertiser"
		/>

	</main>
</template>


<script>
import { mapState, mapGetters } from 'vuex';

import TableTopPanel from '@/components/table-top-panel.vue';
import TablePaginator from '@/components/table-paginator.vue';

import {
	STATUS_AWAITING_APPROVAL,
	STATUS_APPROVED,
} from '@/constants/advertiser.js';

import { profileFormatDateTime as formatDateTime } from '@/utilites';

import advertiserApi from '@/api/advertiser.js';


const STATUS_CHANGE_AWAITING_APPROVAL = {
	value: STATUS_AWAITING_APPROVAL,
	icon: 'warn',
};
const STATUS_CHANGE_APPROVED = {
	value: STATUS_APPROVED,
	icon: 'play',
};


export default {
	name: 'AdvertisersListPage',


	components: {
		TableTopPanel,
		TablePaginator,
	},


	data() {
		return {
			filterOptions: {},
			page: 1,
			pageSize: 20,
			totalCount: 0,
			advertisers: [],
		};
	},


	async mounted() {
		await this.requestAdvertisersWithLoading();
	},


	watch: {
		filterOptions: {
			deep: true,
			handler() {
				this.requestAdvertisersWithLoading();
			}
		},
	},  // END: watch


	computed: {
		...mapState('app', {
			appAgency: 'agency',
			appSide: 'side',
		}),

		...mapGetters('app', [
		  'isDSP',
		]),

		/**
		 * Return statuses for filter
		 */
		statusFilter() {
			const capitalize = this.$options.filters.capitalize;
			const actions = this.actions;

			let result = [
				{
					value: '---',
					label: capitalize(this.$t('advertiser.all_advertisers'))
				},
				{
					value: STATUS_AWAITING_APPROVAL,
					label: capitalize(actions[STATUS_AWAITING_APPROVAL].label)
				},
				{
					value: STATUS_APPROVED,
					label: capitalize(actions[STATUS_APPROVED].label)
				},
			];

			return result;
		},


		actions() {
			return [
				STATUS_CHANGE_AWAITING_APPROVAL,
				STATUS_CHANGE_APPROVED,
			].reduce((actions, status) => {
				actions[status.value] = {
					value: status.value,
					icon: status.icon,
					label: this.$t('advertiser.status_change_' + status.value),
					// labelAction: this.$t('advertiser.status_change_' + status.value + '_action'),
				};
				return actions;
			}, {});
		},


		pagination: {
			get() {
				return {
					page: this.page,
					pageSize: this.pageSize,
				};
			},

			set(pagination) {
				this.page = pagination.page;
				this.pageSize = pagination.pageSize;
				this.requestAdvertisersWithLoading();
			},
		},
	},  // END: computed


	methods: {

		formatDateTime,

		async requestAdvertisersWithLoading() {
			let loading = this.$loading.show();

			const res = await advertiserApi.list(
				this.filterOptions,
				this.pagination.page,
				this.pagination.pageSize,
			);

			this.advertisers = res.results;
			this.totalCount = res.count;

			loading.hide();
		},


		async closeAddAdvertiser() {
			await this.requestAdvertisersWithLoading();
		},


		editAdvertiser(advertiserId) {
			if (!this.isDSP) {
				this.$router.push({
					name: 'advertiser-edit',
					params: {
						advertiserId: advertiserId,
					},
				});
			}
		},
	},  // END: methods
};
</script>


<style lang="sass" scoped>
.page-title
	font-weight: 400
	font-size: 18px
	line-height: 21px
	text-transform: capitalize

	.page-title-icon
		--ni-icon-sign: black
		width: 20px
		height: 19px
		vertical-align: middle
		margin-right: 6px
		margin-top: -3px

.advertiser-row
	cursor: pointer

.status-cell

	.ssp-agency-status-wrapper
		display: grid
		grid-template-columns: 1fr 2fr
		margin-bottom: 10px

		&:last-child
			margin-bottom: 0px

	.ssp-agency-status
		display: flex

	.status-icon
		margin-right: 10px
</style>
