<template>
	<div class="creative-preview"
		@mouseenter="playPreviewVideo"
		@mouseleave="pausePreviewVideo"
	>

		<div class="preview">
			<video
				v-if="creative.type === 'video' && (mediaData.file || mediaData.original_video)"
				ref="video"
				:src="mediaData.file || mediaData.original_video"
				:type="mediaData.mime_type"
				class="video-preview"
				loop
				muted
				playsinline
				preload="metadata"
			/>
			<img
				v-else-if="creative.type === 'video' && mediaData.original_image"
				ref="media"
				class="video-preview"
				:src="mediaData.original_image"
			/>

			<template
				v-if="creative.type === 'audio'"
			>
				<audio
					v-if="mediaData.file"
					ref="audio"
					class="audio-preview"
					:src="mediaData.file"
					:type="mediaData.mime_type"
					playsinline
					muted
					preload="metadata"
				/>
				<nice-icon-2
					icon="melody-15"
					class="preview-icon"
				/>
			</template>


		</div>

		<header class="top">
			<h3 class="title">
				{{ creative.name }}
			</h3>

			<div class="audits-container">
				<template
					v-for="audit in currentAudits"
				>
					<NiceActionList
						v-if="isSSPAdminOrApprover"
						:key="audit.id"
						:status="audit.status"
						:list="auditActionList(audit)"
						icons-in-list
						hide-on-click
						class="status-list"

						@change="auditChangeHandler(audit, $event)"
					/>
					<div
						v-else
						:key="audit.id"
						class="status"
					>
						<span>{{ audit.ssp_agency_name }}</span>
						<nice-icon-2
							:icon="statusForAudit(audit).icon"
							:state="statusForAudit(audit).iconState"
						/>
						<span>{{ $t('campaign.creative_audit_status_' + statusForAudit(audit).label_translation_id) | capitalize }}</span>
					</div>
				</template>
			</div>
		</header>


		<div class="bottom">
			<div class="details">
				<!-- resolution -->
				<div v-if="creative.type === 'video'" class="detail" >
					<nice-label 
						class="detail-label" 
						:error="resolutionIssue"
						:label="$t('campaign.resolution')" 
					/>
					<span class="value" >{{ mediaData.resolution }}</span>
				</div>

				<!-- duration -->
				<div class="detail" >
					<nice-label 
						class="detail-label" 
						:error="durationIssue"
						:label="$t('campaign.duration')" 
					/>
					<span class="value" v-html="formatDuration(mediaData.duration)" />
				</div>

				<!-- upload date -->
				<div class="detail" >
					<nice-label class="detail-label" :label="$t('campaign.uploaded')" />
					<span class="value" v-html="formatDate(creative.ctime)" />
				</div>
			</div>

			<!-- actions -->
			<div class="actions">

				<!-- details -->
				<nice-button-2
					icon-painted
					palette="gray"
					icon="see-24"
					icon-placement="start"
					@click="isDetailsModalShowing = true"
				>{{ $t('campaign.see_details') | capitalize }}</nice-button-2>

				<!-- remove -->
				<nice-button-2
					v-if="isDSP && $listeners.remove"
					icon-painted
					palette="gray"
					icon="basket-24"
					icon-placement="start"
					@click="$emit('remove', creative)"
				>{{ $t('campaign.delete') | capitalize }}</nice-button-2>
			</div>
		</div>

		<!-- Details modal -->
		<creative-details-modal
			v-if="isDetailsModalShowing"
			:creative="creative"
			:context="modalContext"

			:edit-mode="false"
			:show-audit-status="auditable"
			:title="$t('campaign.details')"

			@audit-status-change="detailModalAuditChangeHandler"
			@close="closeDetailsModal"
		/>

		<creative-audit-modal
			v-if="isAuditModalShowing"
			:current-audit="selectedAudit"
			:creative="creative"
			:context="modalContext"
			:change-to="targetStatusCode"
			@apply="auditModalApplyHandler"
			@close="auditModalCloseHandler"
		/>
	</div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import { formatDuration, formatFileSize } from '@/utilites';
import CreativeDetailsModal from '@/components/creative/creative-details-modal';
import CreativeAuditModal from '@/components/creative/creative-audit-modal';
import NiceActionList from '@/ui/nice-action-list';

import AUDIT_STATUS_CODES from '@/constants/audit-status-codes';

import AUDIT_STATUSES, { DEFAULT_CREATIVE_AUDIT } from './audit-statuses';
import { mixin } from './mixins/audit';


export default {
	name: 'CreativePreview',

	mixins: [
		mixin,  // auditActionList,
	],

	components: {
		CreativeDetailsModal,
		CreativeAuditModal,
		NiceActionList,
	},

	props: {
		context: {
			type: Object,
			required: true,
		},

		creative: {
			type: Object,
			required: true,
		},

		/**
		 * Format
		 *   {
		 *     "type": "monitor",
		 *     "resolution": "1080x1920",
		 *     "count":58
		 *   },
		 */
		format: {
			type: Object,
			required: true,
		},



		auditable: {
			type: Boolean,
		},
	},

	data() {
		return {
			isDetailsModalShowing: false,
			isAuditModalShowing: false,
			targetStatusCode: null,

			// show creative-audit-modal for this audit
			selectedAudit: null,

			AUDIT_STATUS_CODES: AUDIT_STATUS_CODES,
		};
	},

	computed: {
		...mapGetters('app', [
			'isSSPAdminOrApprover',
			'isSSPViewer',
			'isDSP',
		]),


		mediaData() {
			let data = this.creative[this.creative.type];
			if (typeof data === 'undefined') {
				return {};
			}

			return data;
		},

		/**
		 * Audits
		 * Returns audits for current campaign/request
		 *
		 * @return {Array} audits list. null audit - means not set
		 */
		currentAudits() {
			const id = this.context.instance.id;

			if (
				!this.creative.audits
				|| this.creative.audits.length == 0
				|| typeof id !== 'number'
			) {
				return [DEFAULT_CREATIVE_AUDIT];
			}

			return this.creative.audits.filter(a => a.campaign === id);
		},

		/**
		 * Extra data for a details modal
		 * @return {object} modal context
		 */
		modalContext() {
			const data = {
				campaign_id: this.context.instance.id,
				campaign_name: this.context.instance.name,
				format: this.format,
				instance: this.context.instance,
			};

			if (this.context.instance.advertiser) {
				data.company_name = this.context.instance.advertiser.name;
			}

			return data;
		},

		media() {
			return this.creative[this.creative.type];
		},

		creativeResolution() {
			return this.media.resolution || '&mdash;';
		},

		creativeScreenResolution() {
			return this.media.screen_resolution || '&mdash;';
		},

		resolutionIssue() {
			if (this.creative.type !== 'video' && this.creative.type !== 'image') {
				return false;
			}

			if (this.creativeResolution === this.creativeScreenResolution) {
				return false;
			}

			return this.$t('errors.creative_reslution_mismatch', {
				current: this.creativeResolution,
				target: this.creativeScreenResolution,
			});
		},

		durationIssue() {
			if (this.context.instance.ad_duration === Math.round(this.media.duration)) {
				return false;
			}

			return this.$t('errors.creative_duration_mismatch', {
				current: this.formatDuration(this.media.duration),
				target: this.formatDuration(this.context.instance.ad_duration),
			});
		},
	},

	methods: {
		...mapActions('creative', {
			updateCreativeAuditForCampaign: 'updateAuditForCampaign',
		}),

		formatDuration,
		formatFileSize,

		formatDate(date) {
			return moment(date).format('DD MMM`YY');
		},

		/**
		 * Returns status label and icon name
		 * @return {object} status
		 */
		statusForAudit(audit) {
			return AUDIT_STATUSES[audit.status];
		},

		closeDetailsModal() {
			this.isDetailsModalShowing = false;
		},

		auditChangeHandler(audit, status) {
			if (status === audit.status) {
				return null;
			}

			this.targetStatusCode = status;
			this.isAuditModalShowing = true;
			this.selectedAudit = audit;
		},

		detailModalAuditChangeHandler(event) {
			this.auditChangeHandler(event.audit, event.status);
		},

		async auditModalApplyHandler({ audit, status, feedback }) {
			await this.toAudit(audit, status, feedback);
		},

		auditModalCloseHandler() {
			this.isAuditModalShowing = false;
			this.selectedAudit = null;
			this.targetStatusCode = null;
		},

		/**
		 * Audit the creative
		 *
		 * TODO: audit is not used currently - we get it from:
		 *  1) creative: this.creative.id
		 *  2) campaign: this.context.instance.id
		 *  3) (ssp) agency: from the URL `/api/v1/agency/1/ssp/...`
		 *
		 * @async
		 * @param {object} audit
		 * @param {number} status
		 * @param {string} feedback
		 * @return {}
		 */
		async toAudit(audit, status, feedback) {
			// async updateAuditForCampaign({ dispatch, state, rootState }, {id, campaign, status, feedback})
			const response = await this.updateCreativeAuditForCampaign({
				id: this.creative.id,
				campaign: this.context.instance.id,
				status,
				feedback,
			});

			// emit event updating the instance
			this.$emit('audit-update');

			return response;
		},

		async playPreviewVideo() {
			if (
				this.creative.type !== 'video'
				|| !this.$refs.video
			) {
				return;
			}

			try {
				return await this.$refs.video.play();
			}
			catch (err) {
				this.$log.error(err);
			}
		},

		pausePreviewVideo() {
			if (this.creative.type !== 'video' || !this.$refs.video) {
				return;
			}
			return this.$refs.video.pause();
		},
	},
};
</script>


<style lang="sass" scoped>
.creative-preview
	display: grid
	grid-template-columns: 160px auto
	grid-template-areas: "preview top" "preview bottom"

	background: $nice_color-gray_light_ultra-2
	border-radius: 10px
	min-height: 125px
	padding: 20px
	column-gap: 30px
	row-gap: 35px

	box-sizing: border-box
	min-height: 125px


.preview
	position: relative
	z-index: 0
	grid-area: preview
	align-self: stretch
	justify-self: stretch
	overflow: hidden
	border-radius: 10px

	min-height: 90px

	display: flex
	align-items: center
	justify-content: center
	background: $nice_color-gray

.preview-icon
	display: block
	position: absolute
	height: 60px
	width: 100%
	opacity: .3
	top: 50%
	left: 0%
	transform: translate3d(0, -50%, 0)
	--ni-icon-sign: currentColor

.progress-bar
	position: absolute
	z-index: 1
	top: 0
	right: 0
	bottom: 0
	left: 0

	&::v-deep > .ni_progress_bar--bar
		height: 100%

		&::before
			top: 0
			height: 100%

		&::after
			top: 0
			height: 100%
			opacity: .7

.video-preview
	position: absolute
	display: block
	height: 100%
	width: 100%
	top: 0
	right: 0
	bottom: 0
	left: 0
	object-fit: cover

.top,
.bottom
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center

.top
	grid-area: top
	align-self: start
	padding-top: 5px

.bottom
	grid-area: bottom
	align-self: end
	// padding-bottom: 5px



.title
	align-self: flex-start
	font-size: $fsz__normal
	font-weight: normal
	margin: 0

.audits-container
	display: flex
	flex-direction: column
	flex-grow: 1
	// align-items: center

.status
	display: flex
	color: $nice_color-darkgray
	font-size: $fsz__smaddle
	align-items: center

.status,
.status-list
	margin-left: auto
	margin-bottom: 10px

	& > :not(:first-child)
		margin-left: 10px

.details
	grid-area: details
	align-self: end

	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center


.detail-label
	margin-top: -8px

.detail
	display: flex
	flex-direction: column

	&:not(:first-child)
		margin-left: 35px

	& > .value
		margin: 0
		line-height: 30px

.value.placeholder
	color: var(--text_2_color)

.actions
	display: flex
	align-items: center
	margin-left: auto

	& > :not(:first-child)
		margin-left: 30px
</style>
