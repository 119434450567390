<template>
	<section
		class="layout-center805 campaign-form-main_info"
	>
		<nice-input
			id="campaign-input-name"
			v-model="instance.name"
			type="text"
			:label="$t('campaign.campaign_name') | capitalize"
			:placeholder="$t('campaign.campaign_name_placeholder') | capitalize"
			required
			:invalid="context.errors.name"
		/>

		<!-- до лучших времен -->

		<!--<nice-select
			v-model="instance.industry"
			:label="$t('campaign.content_category_label') | capitalize"
			:placeholder="$t('campaign.content_category_placeholder') | capitalize"
			:filter-placeholder="$t('campaign.select_placeholder') | capitalize"
			:options="getCategoryOptions"
			:invalid="context.errors.industry"
			id="campaign-select-category"
		/>-->


		<!-- Advertisers -->
		<NiceSelect
			id="campaign-select-advertiser"
			v-model="instance.advertiser"
			:label="$t('campaign.advertiser_label') | capitalize"
			:placeholder="$t('campaign.advertiser_placeholder') | capitalize"
			:filter-placeholder="$t('campaign.select_placeholder') | capitalize"
			:options="getAdvertiserOptions"
			:invalid="context.errors.advertiser"
			class="select-advertiser"
			@input="updateAdvertiserInstance"
		/>

		<div
			class="add-advertiser-row"
		>
			<NiceButton2
				bordered
				class="add-advertiser-button"
				@click="addAdvertiser"
			>
				{{ $t('add') | capitalize }}
			</NiceButton2>
		</div>

		<!--
		<nice-select
			id="campaign-select-brand"
			v-model="instance.brand"
			:label="$t('campaign.brand_label') | capitalize"
			:placeholder="$t('campaign.brand_placeholder') | capitalize"
			:filter-placeholder="$t('campaign.select_placeholder') | capitalize"
			:options="getBrandOptions"
			:invalid="context.errors.brand"
			@input="updateBrandInstance"
		/> -->

		<nice-input
			id="campaign-input-description"
			v-model="instance.description"
			type="text"
			:label="$t('campaign.description') | capitalize"
			:placeholder="$t('campaign.description_placeholder') | capitalize"
			:invalid="context.errors.description"
		/>


		<div class="control-row" >
			<NiceButton2
				filled
				icon-painted
				icon="arrow-14"
				icon-state="-90"
				icon-placement="end"
				:disabled="isAnyRequredFieldEmpty || isAnyErrorsFromBackend"
				class="next-button"
				@click="goToNext"
			>
				{{ $t('campaign.next_btn_label') | capitalize }}
			</NiceButton2>

			<span
				v-if="isAnyRequredFieldEmpty"
				class="control-row__message control-row__message"
			>{{ $t('errors.fill_required_fields') }}</span>

			<span
				v-if="isAnyErrorsFromBackend"
				class="control-row__message control-row__message_errors"
			>{{ $t('errors.resolve_errors') }}</span>
		</div>
	</section>
</template>

<script>
import _ from 'underscore';

import categoryApi from '@/api/category';
import advertiserApi from '@/api/advertiser';
import brandApi from '@/api/brand';
import NiceInput from '@/ui/nice-input';
import NiceSelect from '@/ui/nice-select';
import { profileFormatDateTime as formatDateTime } from '@/utilites';

import {
	STATUS_AWAITING_APPROVAL,
	STATUS_APPROVED,
} from '@/constants/advertiser.js';


const MODEL = Object.freeze({
	prop: 'value',
	event: 'instance_change',
});


// helper status
const ADVERTISER_STATUS_MIXED = 'mixed';


const ADVERTISER_STATUS_ICONS = {
	[ADVERTISER_STATUS_MIXED]: 'hint',
	[STATUS_AWAITING_APPROVAL]: 'warn',
	[STATUS_APPROVED]: 'play',
};


export default {
	name: 'Step01',


	model: MODEL,


	components: {
		NiceInput,
		NiceSelect,
	},


	props: {
		stepCurrent: {
			type: Number
		},

		stepCount: {
			type: Number
		},

		goToNext: {
			type: Function
		},

		goToPrev: {
			type: Function
		},

		[MODEL.prop]: {
			type: Object,
			default: ()=>({}),
		},

		/*
		 * contains `instance`, `step`, `errors`
		 */
		context: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {
			instance: {
				...this[MODEL.prop],
			},
			categoryOptions: [],

			requiredFields: [
				'name',
				// 'industry',
				// 'advertiser',
			],
		};
	},


	created() {
		const defaultValue = {
			name: `Campaign ${formatDateTime(new Date())}`,
		};

		this.instance = _.isEmpty(this[MODEL.prop]) ? defaultValue : this[MODEL.prop];
	},


	computed: {
		isAnyRequredFieldEmpty() {
			return this.requiredFields.some(field => !this.instance[field]);
		},

		isAnyErrorsFromBackend() {
			return [
				'name',
				'industry',
				'advertiser',
				'brand',
			].some(field => this.context.errors[field]);
		},
	},


	methods: {
		async getCategoryOptions({ query, values }) {
			let data = {};
			if (query) {
				data.search = query;
			}
			if (values) {
				data.ids = values;
			}

			const categoryList = (await categoryApi.list(data, 1, 40)).results;
			return _.map(categoryList, category => {
				return {
					value: category.id,
					label: category.name,
				};
			});
		},


		async getAdvertiserOptions({ query, values }) {
			let data = {};
			if (query) {
				data.search = query;
			}
			if (values) {
				data.ids = values;
			}

			const advertisers = (await advertiserApi.list(data, 1, 40)).results;
			return _.map(advertisers, advertiser => {
				return {
					value: advertiser.id,
					label: advertiser.name,
					icon: ADVERTISER_STATUS_ICONS[advertiser.status],
				};
			});
		},


		async getBrandOptions({ query, values }) {
			let data = {};
			if (query) {
				data.search = query;
			}
			if (values) {
				data.ids = values;
			}

			const brands = (await brandApi.list(data, 1, 40)).results;
			return _.map(brands, brand => {
				return {
					value: brand.id,
					label: brand.domain,
				};
			});
		},


		/**
		 * Update advertiser instance after selections
		 */
		async updateAdvertiserInstance(id) {
			if (!id) {
				return;
			}

			const advertiser = await advertiserApi.retrieve(id);
			this.$set(this.instance, 'advertiser_instance', advertiser);
		},


		async updateBrandInstance(id) {
			if (!id) {
				return;
			}

			const brand = await brandApi.retrieve(id);
			this.$set(this.instance, 'brand_instance', brand);
		},


		addAdvertiser() {
			this.$router.push({
			  name: 'advertiser-add',
			});
		},

	},  // END: methods


	watch: {
		[MODEL.prop]: {
			deep: true,
			async handler(newValue, oldValue) {
				if (_.isEqual(newValue, this.instance)) {
					return;
				}

				this.instance = newValue;
			}
		},

		instance: {
			deep: true,
			async handler(newValue, oldValue) {
				if (_.isEqual(newValue, this[MODEL.prop])) {
					return;
				}

				this.$emit(MODEL.event, newValue);
			},
		},
	},  // END: watch

};
</script>

<style lang="sass" scoped >
$width-input: 385px
.campaign-form-main_info
	margin-top: $cmpnt-rc__content-margin-top  // auto 0
	// max-width: $cmpnt-rc__content-width

.nice-input,
.nice-select
	margin: 0 auto 40px
	max-width: $width-input
	width: 100%

	&:last-child
		margin-bottom: 0

.select-advertiser.nice-select
	margin: 0 auto 15px

.add-advertiser-row
	margin: 0 auto 20px
	max-width: $width-input
	width: 100%
	display: flex
	justify-content: flex-end

.control-row
	margin: 0 auto 40px
	max-width: $width-input
	width: 100%
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	// justify-content: flex-end
	// margin-right: $column_indent
	align-items: center

	& > *
		flex-grow: 1
		margin-bottom: 15px

	.next-button
		width: 100%

	.control-row__message
		width: 100%
		color: var(--text_2_color)
		text-align: left

	.control-row__message_errors
		width: 100%
		color: $nice_color-red
		text-align: left
</style>
