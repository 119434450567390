<template>
	<div class="networks-tree-check">
		<nice-tree-check
			ref="tree"
			v-model="localPlatforms"
			:tree="initTree"
			:hideSubtrees="false"
			@ready="selectFakeValueParentIfNeeded"
			@input:lastValue="updateSelectedPlatforms"
		/>
	</div>
</template>

<script>
import NiceTreeCheck from '@/ui/nice-tree-check';

export default {
	name: 'NetworkTreeCheck',

	components: {
		NiceTreeCheck,
	},

	props: {
		platforms: {
			type: Array,
			default: () => [],
		},

		networks: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			localPlatforms: [...this.platforms],

			// testTree: [
			// 	{ value: 'milk-products', label: 'milk-products', parent: null },
			// 	{ value: 'cream', label: 'cream', parent: 'milk-products' },
			// 	{ value: 'milk', label: 'milk', parent: 'milk-products' },
			// 	{ value: 'tmnt', label: 'tmnt', parent: null },
			// 	{ value: 'turtules', label: 'turtules', parent: 'tmnt' },
			// ],
		};
	},

	computed: {
		initTree() {
			const parents = new Set(this.networks.map(item => item.parent));

			const tree = [];

			if (parents.size > 1) {
				for (let item of parents) {
					tree.push({
						value: item.replace(/\s+/g, '-').toLowerCase(),
						label: item,
						title: this.$t('campaign.multi_spp_info_message'),
						parent: null,
					});
				}
			}

			this.networks.forEach(item => {
				let parent = null;
				if (parents.size > 1) { 
					parent = item.parent.replace(/\s+/g, '-').toLowerCase();
				}
				tree.push({
					value: item.value,
					label: item.label,
					title: this.$t('campaign.multi_spp_info_message'),
					parent: parent,
				});
			});

			return tree;
		},
	},

	methods: {
		
		selectFakeValueParentIfNeeded() {
			const treeRef = this.$refs.tree;
			if (!treeRef || !treeRef.flatTree) return;

			const flatTree = treeRef.flatTree;
			const selectedPlatforms = [...this.localPlatforms];

			flatTree.forEach((node) => {
				if (node.parent == null) {
					const anyChildChecked = node.children.some(child => selectedPlatforms.includes(child.value));

					if (anyChildChecked && !selectedPlatforms.includes(node.value)) {
						selectedPlatforms.push(node.value);
					}
				}
			});

			this.localPlatforms = selectedPlatforms;
		},

		updateSelectedPlatforms(value, isChecked) {
			const treeRef = this.$refs.tree;
			if (!treeRef || !treeRef.flatTree) return;

			const flatTree = treeRef.flatTree;
			
			const selectedNode = flatTree.find(node => node.value === value);
			if (!selectedNode) return;

			// if parent
			if (selectedNode.parent === null && isChecked) {
				this.localPlatforms = [value, ...selectedNode.children.map(node => node.value)];
			}

			// if child
			if (selectedNode.parent !== null) {
				if (isChecked) {
					const parent = selectedNode.parent;
					const possibleValues = parent.children.map(node => node.value);
					const selectedPlatforms = [...this.localPlatforms];
					const current = selectedPlatforms.filter(platform => possibleValues.includes(platform));
					const platforms = new Set([parent.value, value, ...current]);
					this.localPlatforms = [...platforms];	
				} else {
					this.selectFakeValueParentIfNeeded();
				}
			}

			this.emitLocalValues();
		},

		getLocalValues() {
			return this.localPlatforms.filter(item => typeof item === 'number');
		},

		emitLocalValues() {
			const ids = this.getLocalValues();
			this.$emit('update:platforms', ids);
		}
	},

	watch: {
		platforms: {
			immediate: true,
			handler(newIds) {
				const localIds = this.getLocalValues();

				const isChanged =
					newIds.length !== localIds.length ||
					JSON.stringify([...newIds].sort()) !== JSON.stringify([...localIds].sort());

				if (!isChanged) return;
			
				if (localIds.length == 0) {
					// get data from BE only if localPlatforms is empty
					this.localPlatforms = newIds;
					this.selectFakeValueParentIfNeeded();
				} else {
					// so that the component does not glitch, it will update BE, if something is not sync
					this.emitLocalValues();
				}
			},
		},
	},
};
</script>

<style scoped>
</style>