<template>
<main>
	<section class="layout-full_width">

	<h1 class="page-title">
		<!-- TODO: -->
		<!-- <nice-icon2
			icon="campaigns"
			class="page-title-icon"
		/> -->
		{{ $t('page_caption_request-list') }}
	</h1>

	<table-top-panel
		v-model="filterOptions"
		:add-title="isDSP ? $t('request_list.add_request') : '' | capitalize"
		:add-route="{
			name: 'request-add',
		}"
		:show-only-my="isDSP"
		:status-list="statusFilter"
	/>

	<table class="nice-table">
		<thead>
		<tr>
			<th>{{ $t('request_list.id') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.name') }}&nbsp;&mdash;</th>
			<th v-if="appSide == 'ssp'">{{ $t('request_list.dsp_agency') }}&nbsp;&mdash;</th>
			<th class="header-status">{{ $t('request_list.status') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.budget') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.devices') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.start') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.end') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.created') }}&nbsp;&mdash;</th>
			<th>{{ $t('request_list.updated') }}&nbsp;&mdash;</th>
		</tr>
		</thead>
		<tbody>
		<template v-if="requestListTotalCount">
			<tr
				v-for="request in requestList"
				:key="request.id"
				:class="`request_${request.id}_row`"
			>
				<td>{{ request.id }}</td>
				<table-cell-name
					:id="request.id"
					resource="request"
					:caption="request.name"
					:router= getRoute(request)
					show-link
				/>
				<td v-if="isSSP">
					{{ request.owner_instance.name }}
				</td>
				<table-cell-campaign-audits
				  :campaign-id="request.id"
					:audits="requestAuditsWithDefaultStatus(request)"
					:statuses="actions"
					:hide-ssp-name="isSSP"
				/>
				<!-- <table-cell-status
					resource="request"
					:item="request"
					:list="getStatusListForChange(request.status)"
					:can-change="canChangeStatus(request.status)"
					icons-in-list
				/> -->

				<td class="col-price" v-html="formatCash(request.budget, request.currency)"></td>
				<td class="col-device_count">{{ request.device_count }}</td>

				<td>{{ formatDate(request.start_date) }}</td>
				<td>{{ formatDate(request.end_date) }}</td>
				<table-cell-author :author="request.created_by" :datetime="formatDateTime(request.ctime)" />
				<table-cell-author :author="request.modified_by" :datetime="formatDateTime(request.mtime)" />
			</tr>
		</template>

		<tr v-else class="table-empty">
			<td colspan="9">{{ $t('request_list.empty') }}</td>
		</tr>
		</tbody>
	</table>

	<table-paginator
		v-model="pagination"
		:total="requestListTotalCount"
		:paginator-props="{ queryKey: 'p' }"
	/>
	</section>

	<!-- AddPackage modal should be there -->
	<router-view />
</main>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import TableCellName from '@/components/table/cell-name';
import TableCellAuthor from '@/components/table/cell-author';
// import TableCellStatus from '@/components/table/cell-status';
import TableCellCampaignAudits from '@/components/table/cell-campaign-audits';
import TableTopPanel from '@/components/table-top-panel.vue';
import TablePaginator from '@/components/table-paginator.vue';
import {
	formatCash,
	profileFormatDate as formatDate,
	profileFormatDateTime as formatDateTime,
} from '@/utilites';
import {
	DRAFT as STATUS_DRAFT,
	PENDING_AUDIT as STATUS_AWAITING_APPROVAL,
	PRE_APPROVED as STATUS_PRE_APPROVED,
	APPROVED as STATUS_APPROVED,
	ARCHIVED as STATUS_ARCHIVED,
	DENIED as STATUS_REJECTED,
	DELETED as STATUS_DELETED,
	// TODO: something like components/creative/audit-statuses.js?
	REQUEST_AUDIT_STATUSES,
} from '@/constants/audit-status-codes';


const STATUS_CHANGE_DRAFT = {
	value: STATUS_DRAFT,
	icon: 'draft',
};
const STATUS_CHANGE_AWAITING_APPROVAL = {
	value: STATUS_AWAITING_APPROVAL,
	icon: 'warn',
};
const STATUS_CHANGE_APPROVED = {
	value: STATUS_APPROVED,
	icon: 'play',
};
const STATUS_CHANGE_PRE_APPROVED = {
	value: STATUS_PRE_APPROVED,
	icon: 'play',
};
const STATUS_CHANGE_REJECTED = {
	value: STATUS_REJECTED,
	icon: 'error',
};
const STATUS_CHANGE_ARCHIVED = {
	value: STATUS_ARCHIVED,
	icon: 'arrow_circle',
};
const STATUS_CHANGE_DELETED = {
	value: STATUS_DELETED,
	icon: 'basket',
};


export default {
	name: 'PageRequestList',

	components: {
		TableCellAuthor,
		TableCellName,
		// TableCellStatus,
		TableCellCampaignAudits,
		TablePaginator,
		TableTopPanel
	},

	data() {
		return {
			filterOptions: {},
			REQUEST_AUDIT_STATUSES,
			STATUS_DRAFT,
			STATUS_AWAITING_APPROVAL,
			STATUS_PRE_APPROVED,
			STATUS_APPROVED,
			STATUS_ARCHIVED,
			STATUS_REJECTED,
			STATUS_DELETED
		};
	},

	computed: {
		...mapGetters('app', { appIsLoading: 'isLoading' }),
		...mapState('app', { appAgency: 'agency', appSide: 'side' }),
		...mapState('request', {
			requestList: 'list',
			requestListTotalCount: 'totalCount',
			requestListPage: 'page',
			requestListPageSize: 'pageSize'
		}),

		actions(){
			return [STATUS_CHANGE_DRAFT,
				STATUS_CHANGE_AWAITING_APPROVAL,
				STATUS_CHANGE_PRE_APPROVED,
				STATUS_CHANGE_APPROVED,
				STATUS_CHANGE_REJECTED,
				STATUS_CHANGE_ARCHIVED,
				STATUS_CHANGE_DELETED,
			].reduce((actions, status) => {
				actions[status.value] = {
					value: status.value,
					icon: status.icon,
					label: this.$t('request_list.status_change_' + status.value),
					labelAction: this.$t('request_list.status_change_' + status.value +'_action'),
				};
				return actions;
			}, {});
		},

		isSSP() {
			return this.appSide == 'ssp';
		},

		isDSP() {
			return this.appSide == 'dsp';
		},

		/**
	* Return statuses for filter
	*/
		statusFilter() {
			const capitalize = this.$options.filters.capitalize;
			const actions = this.actions;
			let result = [
				{ value: '---',						label: capitalize(this.$t('request_list.request_status_all_requests')) },
				{ value: STATUS_PRE_APPROVED,		label: capitalize(actions[STATUS_PRE_APPROVED].label) },
				{ value: STATUS_AWAITING_APPROVAL,	label: capitalize(actions[STATUS_AWAITING_APPROVAL].label) },
				// { value: STATUS_APPROVED,			label: capitalize(actions[STATUS_APPROVED].label) },
				{ value: STATUS_REJECTED,			label: capitalize(actions[STATUS_REJECTED].label) }
			];
			if (this.isDSP) {
				result.splice(1, 0, { value: STATUS_DRAFT,	label: capitalize(actions[STATUS_DRAFT].label) });
				result.push({ value: STATUS_ARCHIVED,		label: capitalize(actions[STATUS_ARCHIVED].label) });
				// result.push({ value: STATUS_DELETED,		label: capitalize(actions[STATUS_DELETED].label) });
			}
			return result;
		},

		pagination: {
			get() {
				return {
					page: this.requestListPage,
					pageSize: this.requestListPageSize
				};
			},

			set(pagination) {
				this.requestWithLoading({
					filters: this.filterOptions,
					...pagination
				});
			}
		}
	},

	methods: {
		...mapActions('request', {
			requestRequestList: 'requestList'
		// 'requestRequestChange': 'change'
		}),

		formatCash,
		formatDate,
		formatDateTime,

		/**
		* Return list of available statuses for change
		*/

		async requestWithLoading(options) {
			let loading = this.$loading.show();
			await this.requestRequestList(options);
			loading.hide();
		},

		// TODO: not used currency
		getStatusListForChange(status) {
			let result = [];


			if (this.isSSP) {
				switch (status) {
				case STATUS_AWAITING_APPROVAL:
					result = [
						STATUS_CHANGE_PRE_APPROVED,
						STATUS_CHANGE_APPROVED,
						STATUS_CHANGE_REJECTED,
						STATUS_CHANGE_AWAITING_APPROVAL
					];
					break;
				case STATUS_REJECTED:
					result = [
						STATUS_CHANGE_REJECTED
					];
					break;

				case STATUS_PRE_APPROVED:
					result = [
						STATUS_CHANGE_APPROVED,
						STATUS_CHANGE_REJECTED,
						STATUS_CHANGE_PRE_APPROVED
					];
					break;

				case STATUS_APPROVED:
					result = [
						STATUS_CHANGE_APPROVED
					];
					break;
				}
			} else {
			// DSP
				switch (status) {
				case STATUS_DRAFT:
					result = [
						STATUS_CHANGE_AWAITING_APPROVAL,
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_DRAFT
					];
					break;

				case STATUS_PRE_APPROVED:
					result = [
						STATUS_CHANGE_PRE_APPROVED,
						STATUS_CHANGE_AWAITING_APPROVAL,
						STATUS_CHANGE_ARCHIVED
					];
					break;

				case STATUS_AWAITING_APPROVAL:
					result = [
						STATUS_CHANGE_DRAFT,
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_AWAITING_APPROVAL
					];
					break;

				case STATUS_APPROVED:
					result = [
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_APPROVED
					];
					break;

				case STATUS_REJECTED:
					result = [
						STATUS_CHANGE_AWAITING_APPROVAL,
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_REJECTED
					];
					break;

				case STATUS_ARCHIVED:
					result = [
						STATUS_CHANGE_DELETED,
						STATUS_CHANGE_ARCHIVED
					];
					break;
				}
			}

			const finalResult = result.map(el => this.actions[el.value]);
			return finalResult;
		},

		canChangeStatus(status) {
			if (this.isSSP) {
				return status === STATUS_AWAITING_APPROVAL || status === STATUS_PRE_APPROVED;
			}

			// dsp
			return status !== STATUS_DELETED;
		},

	  getRoute(request) {
		  const data = request.status[0];
		  if (data && data.status === STATUS_APPROVED) {
			  return {
				  'name': 'campaign-details',
				  'params': {id: request.campaign}
			  };
		  }
			else {
			  return {
				  'name': 'request-details',
				  'params': {id: request.id}
			  };
		  }
	  },

		/**
		 * Return draft audit if empty
		 */
		requestAuditsWithDefaultStatus(request) {
			// default
			if (request.status.length == 0) {
				return [
					{
						campaign: request.id,
						id: null,
						ssp_agency: null,
						ssp_agency_name: '—',
						status: STATUS_DRAFT,
					}
				];
			}

			return request.status;
		},
	},  // END: methods

	watch: {
		appAgency(value, oldValue) {
			if (value && value !== oldValue) {
				this.requestWithLoading({ filters: this.filterOptions, force: true });
			}
		},

		filterOptions: {
			deep: true,
			handler() {
				if (this.appAgency) {
					this.requestWithLoading({ filters: this.filterOptions, force: true });
				}
			}
		}
	},
};
</script>

<style lang="sass" scoped >
.page-title
	font-weight: 400
	font-size: 18px
	line-height: 21px
	text-transform: capitalize

	.page-title-icon
		--ni-icon-sign: black
		vertical-align: middle
		margin-left: -8px


// .header-status
// 	text-align: right

.button-status_change,
.col-status
	+button__clear

	&>.nice-icon,
	&>span
		display: inline-block
		text-transform: capitalize
		vertical-align: middle

	&>span
		margin-left: 0.8em

.status_change
	@extend %button__text

	padding: 5px 5px
	font-size: 11px

.col-device_count,
.col-price
	color: $nice_color-default

.table-empty
	text-align: center
</style>
